<template>
  <div class="card-content">
    <h4 class="text-center">オーナー登録情報</h4>
    <div>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Email"
            placeholder="Email@mail.com"
            :isValid="isEmailValid"
            :invalidFeedback="emailFeedback"
            v-model="email"
          />
        </CCol>
        <CCol sm="12">
          <CInput label="Username" placeholder="Username" v-model="username" />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Password"
            type="password"
            v-model="password"
            :isValid="isPasswordValid"
            :invalidFeedback="passwordFeedback"
            :wasValidated="isConfirmPassword"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Confirm Password"
            type="password"
            v-model="confirmPassword"
            :isValid="isConfirmPasswordValid"
            :invalidFeedback="confirmPasswordFeedback"
            :wasValidated="isConfirmPassword"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Fisrt Name"
            placeholder="Fisrt Name"
            v-model="firstName"
            :isValid="isfirstNameValid"
            :invalidFeedback="firstNameFeedback"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Last Name"
            placeholder="Fisrt Name"
            v-model="lastName"
            :isValid="isLastNameValid"
            :invalidFeedback="lastNameFeedback"
          />
        </CCol>
        <CCol sm="12">
          <CInput label="Birth Day" type="date" v-model="birthDay" />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Phone"
            placeholder="0XX-XXX-XXXX"
            v-model="phoneNumber"
          />
        </CCol>
        <CCol sm="6">
          <label>Sex</label>
          <CInputRadioGroup
            @click="test($event)"
            :inline="true"
            :options="[
              { value: '1', label: 'Nữ' },
              { value: '2', label: 'Nam' },
              {
                value: '3',
                label: 'Khác',
                props: { desription: 'description text' },
              },
            ]"
          />
        </CCol>
        <CCol sm="6">
          <CInput label="Zip" placeholder="XXX-XXXX" v-model="postalCode" />
        </CCol>
        <CCol sm="12">
          <CInput label="Address" v-model="address" />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Store Name"
            v-model="storeName"
            :isValid="isStoreNameValid"
            :invalidFeedback="storeNameFeedback"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Website URL"
            :readonly="true"
            disabled
            v-model="websiteUrl"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <div class="d-flex justify-content-center">
            <CButton
              v-on:click.prevent="createOwner()"
              :color="'primary'"
              :size="'lg'"
              class="mt-3"
            >
              Create Owner
            </CButton>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { FeedBack } from "@/utils/feedback.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateOwner",
  data() {
    return {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      birthDay: "",
      phoneNumber: "",
      sex: "1",
      postalCode: "",
      address: "",
      storeName: "",
      websiteUrl: `${process.env.VUE_APP_BASE_URL}/`,
      isEmailValid: null,
      emailFeedback: "",
      isPasswordValid: null,
      passwordFeedback: "",
      isConfirmPassword: null,
      isConfirmPasswordValid: null,
      confirmPasswordFeedback: "",
      isfirstNameValid: null,
      firstNameFeedback: "",
      isLastNameValid: null,
      lastNameFeedback: "",
      isStoreNameValid: null,
      storeNameFeedback: "",
    };
  },
  computed: {
    ...mapGetters(["success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    postalCode() {
      this.getAdress(this.postalCode);
    },
    email() {
      this.isEmailValid = null;
    },
    password() {
      this.isPasswordValid = null;
      if (this.password === this.confirmPassword) {
        this.isConfirmPassword = true;
      } else {
        this.isConfirmPassword = false;
      }
    },
    confirmPassword() {
      this.isConfirmPasswordValid = null;
      if (this.password === this.confirmPassword) {
        this.isConfirmPassword = true;
      } else {
        this.isConfirmPassword = false;
      }
    },
    firstName() {
      this.isfirstNameValid = null;
    },
    lastName() {
      this.isLastNameValid = null;
    },
    storeName() {
      this.isStoreNameValid = null;
      this.websiteUrl = `${process.env.VUE_APP_BASE_URL}/${this.storeName}`;
    },
  },
  methods: {
    test(e) {
      console.log(e);
    },
    ...mapActions({ createOwnerDefault: "createOwnerDefault" }),
    getAdress(postcode) {
      var postal_code = require("japan-postal-code");
      const _this = this;
      postal_code.get(postcode, function (address) {
        _this.address =
          address.street + address.prefecture + address.city + address.area;
      });
    },
    createOwner() {
      if (this.email === "") {
        this.isEmailValid = false;
        this.emailFeedback = FeedBack.EMAILNULL;
      } else if (this.password === "") {
        this.isPasswordValid = false;
        this.passwordFeedback = FeedBack.PASSWORDNULL;
      } else if (this.confirmPassword === "") {
        this.isConfirmPasswordValid = false;
        this.confirmPasswordFeedback = FeedBack.PASSWORDNULL;
      } else if (this.confirmPassword !== this.password) {
        this.isConfirmPasswordValid = false;
        this.confirmPasswordFeedback = FeedBack.PASSWORDINCORRECT;
      } else if (this.firstName === "") {
        this.isfirstNameValid = false;
        this.firstNameFeedback = FeedBack.FIRSTNAMENULL;
      } else if (this.lastName === "") {
        this.isLastNameValid = false;
        this.lastNameFeedback = FeedBack.LASTNAMENULL;
      } else if (this.storeName === "") {
        this.isStoreNameValid = false;
        this.storeNameFeedback = FeedBack.STORENAMENULL;
      } else {
        const {
          email,
          username,
          password,
          firstName,
          lastName,
          birthDay,
          phoneNumber,
          sex,
          postalCode,
          address,
          storeName,
          websiteUrl,
        } = this;
        const formCreateOwner = {
          email,
          nick_name: username,
          password,
          first_name: firstName,
          last_name: lastName,
          birthday: birthDay,
          tel: phoneNumber,
          sex,
          postal_code: postalCode,
          address,
          shop_name: storeName,
          url_site: websiteUrl,
        };
        this.createOwnerDefault(formCreateOwner);
      }
    },
  },
};
</script>
